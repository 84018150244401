<script setup lang="ts">
import { ParagraphText } from '~/api/graphql/generated'

const props = defineProps<{
  entity?: ParagraphText
  text?: string
}>()
const text = computed(() => props.text || props.entity?.fieldText?.processed || '')
</script>

<template>
  <Section v-animate="{ animation: 'vertical-reveal' }">
    <TextSpecial class="t-10 t-o-1 tl-7 tl-o-0 ds-o-1" :text="text" />
  </Section>
</template>
<style scoped lang="scss">
.section {
  &:not(:last-child) {
    --margin: var(--p-mb);
    > div > :deep(*:last-child) {
      margin-bottom: 0;
    }
  }
}
</style>
